function chkState(val, validationModel) {	
  const field = validationModel[val];
  return !field.$dirty || !field.$invalid;
}

function chkTimeState(val, validationModel) {
  const field = validationModel[val];
  if (field.$dirty == true && field.hasTwentyFourHourBuffer == false) {
    return false;
  }
  return true;
}

function chkDateToAndFromDateState(val, validationModel) {
  const field = validationModel[val];
  if (
    field.$dirty == true &&
    field.checkToDateTimeNotBeforeFromDateTime == false
  ) {
    return false;
  }
  return true;
}

export function checkStrongPassword(val) {
   no-useless-escape
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  if (false === strongRegex.test(val)) return false;
  else return true;
}

export function checkValidPhoneNumber(val) {
  // if (val.includes("#"))
  //   return false;
  // return true;

    return /^(1\s|1|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/.test(val);

}

export function checkValidEmails(val){
  return /(\s*;|,\s*|\s*$)?(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])(\s*,\s*|\s*$)/g.test(val);
}

export default {
  chkState,
  chkTimeState,
  chkDateToAndFromDateState,
  checkStrongPassword,
  checkValidPhoneNumber,
  checkValidEmails
};
