<template id="tmplResetPassword">
  <div id="divlResetPassword" class="app flex-row align-items-center">
    <div id="tmplResetPasswordParent" class="container vld-parent">
      <loading
        id="ldingResetPassword"
        :active.sync="isLoading"
        :is-full-page="true"
        color="#114C8E"
      ></loading>
      <b-row id="rowResetPassword" class="justify-content-center">
        <b-col id="colResetPassword" md="8" lg="5" sm="10" xs="12">
          <b-card-group id="crdGrpResetPassword">
            <b-card id="crdResetPassword" no-body class="p-4">
              <b-card-body id="crdbdyResetPassword">
                <b-form id="frmResetPassword" novalidate>
                  <h3 id="hdrChangePassword" class="text-center">Change Password</h3>
                  <p id="pconfirmNewPassword" class="text-center">
                    Please enter and confirm your new password.
                  </p>
                  <p id="pPasswordReqs" class="text-center">
                    Password must be 8 characters long, contain uppercase,
                    lowercase, numeric, and special characters.
                  </p>
                  <b-form-group id="frmGrpNewPassword" label="New Password" label-for="txtPassword">
                    <b-form-input
                      id="txtPassword"
                      type="password"
                      class="form-control"
                      v-model="password"
                      :state="
                        inputValidations.chkState('password', $v) == false
                          ? false
                          : null
                      "
                    />
                    <b-form-invalid-feedback id="frmInvFdBkPasswordRequired"
                      v-if="!$v.password.required && $v.password.$dirty"
                      >Password is required.</b-form-invalid-feedback
                    >
                    <b-form-invalid-feedback id="frmInvFdBkPasswordLength"
                      v-if="!$v.password.minLength && $v.password.$dirty"
                      >Password must be at least 8 characters
                      long.</b-form-invalid-feedback
                    >
                  </b-form-group>

                  <b-form-group id="frmGrpConfirmPassword"
                    label="Confirm Password"
                    label-for="txtConfirmPassword"
                  >
                    <b-form-input
                      id="txtConfirmPassword"
                      type="password"
                      class="form-control"
                      v-model="confirmPassword"
                      :state="
                        inputValidations.chkState('confirmPassword', $v) ==
                        false
                          ? false
                          : null
                      "
                      @keyup.enter="resetPassword()"
                    />
                    <b-form-invalid-feedback id="frmInvFdBkMatchingRequired"
                      :state="inputValidations.chkState('confirmPassword', $v)"
                      >Matching password required.</b-form-invalid-feedback
                    >
                  </b-form-group>
                  <b-row id="rowChangePassword" class="text-center">
                    <b-col id="colChangePassword">
                      <b-button
                        id="btnChangePassword"
                        variant="primary"
                        class="px-4"
                        @click="resetPassword()"
                        >Change Password</b-button
                      >
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import inputValidations from "@/shared/utils/input-validation";
import { handleRequestError } from "@/shared/utils/response-error-handler";
import { mapState } from 'vuex';

export default {
  name: "ResetPassword.vue",
  components: {
    Loading
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      isLoading: false,
      inputValidations: inputValidations,
    };
  },
  methods: {
    async resetPassword() {
      try {
        this.isLoading = true;

        this.$v.$touch();
        if (!this.$v.$invalid) {

          var authData = {
            email: this.$store.state.user.email,
            newPassword: this.password,
            confirmNewPassword: this.confirmPassword
          };

          if (this.$store.state.user.resetToken) {
            authData.tempToken = decodeURI(this.$store.state.user.resetToken);
          } else {
            authData.tempToken = this.$route.query.resetToken;
            authData.email = decodeURI(this.$route.query.email);
          }

          await this.$store.dispatch("user/resetPasword", authData);
          this.$router.push({ name: "dashboard" });
        }
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
      this.isLoading = false;
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(8)
      //checkStrongPassword
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs("password")
    }
  }
};
</script>

<style scoped>
</style>

